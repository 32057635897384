import { useNavigate } from 'react-router'

const Header = () => {
    const navigate = useNavigate()

    const onClickLogOut = (e) => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/')
    }
    return (<>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* <!-- Left navbar links --> */}
            <ul className="navbar-nav">
                <li className="nav-item d-none d-sm-inline-block">
                    <span className="nav-link brand">Project TimeLine Tracker</span>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
                        <i className="fa fa-user-circle"></i> User
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ left: "inherit", right: "0px" }}>
                        <div className="dropdown-divider"></div>
                        <a onClick={onClickLogOut} className="dropdown-item logout">
                            Logout
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    </>);
}
export default Header;