import React, { useState, useEffect } from 'react'
import Layout from '../layout/layout'
import Footer from '../layout/footer'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const data = [
    {
        id: 1,
        name: 'John Doe',
        age: 30,
        gender: 'Male',
    },
    {
        id: 2,
        name: 'Jane Doe',
        age: 25,
        gender: 'Female',
    },
    {
        id: 3,
        name: 'Bob Smith',
        age: 40,
        gender: 'Male',
    },
];

const customStyles = {
    rows: {
      style: {
        minHeight: '56px',
        fontSize:'16px'
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f7f7f7',
        borderBottomWidth: '1px',
        borderBottomColor: '#eee',
        minHeight: '56px',
      },
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    cells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    pagination: {
      style: {
        borderTopWidth: '1px',
        borderTopColor: '#ddd',
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
};

const ClientPortal = () => {

    const [lgShow, setLgShow] = useState(false);
    const [clientName, setClientName] = useState('');
    const [actionType, setActionType] = useState('Add');
    const token = localStorage.getItem("token");
    const [rowId, setRowId] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [data, setData] = useState([]);

    const columns = [
        {
            name: 'S.No',
            selector: '_id',
            sortable: true,
            cell: (row) => <ActionColumnUser row={row} />
        },
        {
            name: 'Client',
            selector: 'clientName',
            sortable: true,
            cell: (row) => <ActionColumnProject row={row} />
        },
        {
            name: 'Action',
            selector: 'id',
            cell: (row) => <ActionColumn row={row} />,
            width: '200px',
        },
    ];

    const ActionColumnUser = ({ row }) => {
        return (
            <>

                <Link to={`/client/${row._id}/user`}>{row._id}</Link>

            </>
        );
    };


    const ActionColumnProject = ({ row }) => {
        return (
            <>

                <Link to={`/client/${row._id}`}>{row.clientName}</Link>

            </>
        );
    };

    const ActionColumn = ({ row }) => {
        return (
            <>
                <Button variant='primary' className="btn-sm" onClick={() => OpenEditCompany(row)}>
                    Edit
                </Button>
                &nbsp;&nbsp;
                <Button variant='danger' className="btn-sm" onClick={() => handleDelete(row)}>
                    Delete
                </Button>
            </>
        );
    };


    const addCompany = async () => {

        try {
            if (!clientName)
                toast.error("organization Name is Required");
            else {
                if (actionType == 'Add') {
                    const data = {
                        clientName: clientName
                    }

                    console.log("token", token)
                    const requestOptions = {
                        headers: { 'Content-Type': 'application/json', Authorization: token }
                    };
                    const addedCompany = await axios.post(`https://pttracker.acompworld.com/backend/api/v1/clients`, data, requestOptions);
                    if (addedCompany.data.success) {
                        toast.success("Client Added Successfully");
                        setLgShow(false);
                        loadClient();
                    }
                }
                else {
                    const data = {
                        clientName: clientName
                    }

                    const requestOptions = {
                        headers: { 'Content-Type': 'application/json', Authorization: token }
                    };

                    const updateCompany = await axios.put(`https://pttracker.acompworld.com/backend/api/v1/clients/${rowId._id}`, data, requestOptions);
                    if (updateCompany.data.success) {
                        toast.success("Client Updated Successfully");
                        setLgShow(false);
                        loadClient();

                    }


                }

            }

        }

        catch (ex) {
            toast.error(ex);
        }
    }

    const OpenEditCompany = async (row) => {
        try {
            console.log("row--", row)
            setRowId(row);
            setActionType('Edit');
            const requestOptions = {
                headers: { 'Content-Type': 'application/json', Authorization: token }
            };
            const getClient = await axios.get(`https://pttracker.acompworld.com/backend/api/v1/clients/${row._id}`, requestOptions);
            if (getClient.data.success) {

                const result = getClient.data.data;
                setClientName(result.clientName)
                setLgShow(true);

            }

        }
        catch (ex) {

        }
    }

    const handleDelete = (row) => {
        setRowId(row);
        setShowDeleteModal(true);

    }

    const DeleteClient = async () => {
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', Authorization: token }
        };
        const deleteComany = await axios.delete(`https://pttracker.acompworld.com/backend/api/v1/clients/${rowId._id}`, requestOptions);
        // console.log("deleted product", deleteProduct);
        if (deleteComany.data.success == true) {
            toast.success("Client Deleted Successfully");

            setShowDeleteModal(false);
            loadClient();


        }
    }

    const loadClient = async () => {

        try {
            const requestOptions = {
                headers: { 'Content-Type': 'application/json', Authorization: token }
            };
            const result = await axios.get(`https://pttracker.acompworld.com/backend/api/v1/clients`, requestOptions);
            if (result.data.success) {

                setData(result.data.data);

            }
        }
        catch (ex) {
            toast.error(ex);
        }
    }

    useEffect(() => {


        loadClient();


    }, [])

    useEffect(() => {
        if (lgShow == false) {
            loadClient();
            setClientName('');
            setActionType('Add');
        }
    }, [lgShow]);

    return (
        <>
            <div className="wrapper">
                <Layout />
                <div className="pageContent">
                    <div className="heading-compines">
                        <h4 className='pull-left'>Clients</h4>
                        <Button className='pull-right' onClick={() => setLgShow(true)}>Add Client</Button>
                    </div>
                    <Modal
                        size="lg"
                        show={lgShow}
                        onHide={() => setLgShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                "Add New Client"
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-lg-7">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Client Name
                                        </Form.Label>
                                        <Form.Control type="text" placeholder='' value={clientName}
                                            onChange={(e) => setClientName(e.target.value)} />

                                    </Form.Group>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={addCompany}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                            <Button variant="primary" onClick={() => DeleteClient()}>Delete</Button>
                        </Modal.Footer>
                    </Modal>

                    <DataTable
                        columns={columns}
                        data={data}
                        title=""
                        className="table-tab"
                        pagination
                        customStyles={customStyles}
                    />

                </div>

            </div>

            <Footer />
        </>
    )
}

export default ClientPortal