import React, { useEffect, useState } from 'react'
import Layout from '../layout/layout'
import Footer from '../layout/footer'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import 'react-vertical-timeline/style.css';
import '../../timeline.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import * as ReactIcons from 'react-icons/bs';
import * as ReactIcon from 'react-icons/ri';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CloseButton from 'react-bootstrap/CloseButton';

import TriggerExample from './tooltip-button';

const BaseUrl = `https://pttracker.acompworld.com/backend/api/v1`;
const Clients = () => {

  const { _id } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  const [projectOngoing, setProjectOngoing] = useState([]);
  const [projectCompleted, setProjectCompleted] = useState([]);
  const [projectBtn, setProjectBtn] = useState('Ongoing');
  const [timeline, setTimeline] = useState([]);
  const [deleteProject, setDeleteProject] = useState(false);
  const [row, setRow] = useState({});
  const [eventModal, setEventModal] = useState(false);
  const [projectModal, setProjectModal] = useState(false);
  const [actionType, setActionType] = useState('Add');
  const [timelineId, setTimelineId] = useState({});
  const [eventBtn, setEventBtn] = useState(false);
  const [event, setEvent] = useState({
    eventName: "",
    eventDate: "",
    clientActionRequired: false,
    attachements: [],
    description: null
  });

  const [project, setProject] = useState({
    projectName: '',
    description: ''
  });

  const [attatchment, setAttatchment] = useState([{
    fileName: '',
    filePath: ''
  }, {
    fileName: '',
    filePath: ''
  }, {
    fileName: '',
    filePath: ''
  }]);


  useEffect(() => {
    loadProjects();
  }, [_id, projectBtn]);

  useEffect(() => {
    if (eventModal === false) {
      setAttatchment([{
        fileName: '',
        filePath: ''
      }, {
        fileName: '',
        filePath: ''
      }, {
        fileName: '',
        filePath: ''
      }]);

      setEvent({
        eventName: "",
        eventDate: "",
        clientActionRequired: false,
        attachements: [],
        description: null
      });

      setActionType('Add');
    }

  }, [eventModal]);

  const loadProjects = async () => {
    try {
      const requestOptions = {
        headers: { 'Content-Type': 'application/json', Authorization: token }
      };
      console.log("client", _id);
      const getProject = await axios.get(`${BaseUrl}/clients/${_id}/projects`, requestOptions);
      if (getProject.data.success) {
        console.log(getProject.data.data);
        console.log(projectBtn);
        //setProject(getProject.data.data);
        setProjectOngoing(getProject.data.data.ongoingProjects);
        setProjectCompleted(getProject.data.data.completedProjects)

      }
    }
    catch (ex) {
      toast.error(ex);
    }
  }

  const loadTimeLine = async (item) => {
    try {
      setRow(item);
      setEventBtn(true);
      const requestOptions = {
        headers: { 'Content-Type': 'application/json', Authorization: token }
      };
      const getTimeline = await axios.get(`${BaseUrl}/projects/${item._id}/events`, requestOptions);
      if (getTimeline.data.success) {
        console.log(getTimeline.data.data);
        /*  console.log(projectBtn); */
        setTimeline(getTimeline.data.data);
        console.log("Items", item);

      }
    }
    catch (ex) {

    }
  }

  const openEventModal = () => {
    setEventModal(true);
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setEvent(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value
    }));
  }



  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value, index);

    const list = [...attatchment];
    list[index][name] = value;
    setAttatchment(list);

  };

  const handleOnFileUpload = async (e, index) => {
    e.preventDefault();
    const targetFileName = e.target.name;
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("files", file);
    const requestOptions = {
      headers: { 'Content-Type': 'multipart/form-data', Authorization: token }
    };
    try {
      const upload = await axios.post(`${BaseUrl}/uploads`, formdata, requestOptions);
      if (upload.data.success) {

        console.log(upload.data.data);
        const list = [...attatchment];

        list[index][targetFileName] = upload.data.data.file_path;
        setAttatchment(list);


      }
    }
    catch (ex) {
      alert(ex);
    }



  }

  const addEvent = async () => {
    try {
      const requestOptions = {
        headers: { 'Content-Type': 'application/json', Authorization: token }
      };

      if (!event.eventName) {
        toast.error('Event Name is required ');
      }
      else if (!event.eventDate) {
        toast.error("Event date is required");
      }
      else {
        const attatches = attatchment.filter(item => item.fileName !== '' && item.filePath !== '');
        const data = {
          eventName: event.eventName,
          eventDate: event.eventDate,
          clientActionRequired: event.clientActionRequired,
          attachements: attatches,
          description: event.description

        };
        if (actionType === 'Add') {

          console.log(data);
          const addedEvent = await axios.post(`${BaseUrl}/projects/${row._id}/events`, data, requestOptions);
          if (addedEvent.data.success) {


            setEventModal(false);
            toast.success("Event Added Successfully");
            loadTimeLine(row);
          }
        }
        else {
          const updatedEvent = await axios.put(`${BaseUrl}/projects/${row._id}/events/${timelineId._id}`, data, requestOptions);
          if (updatedEvent.data.success) {


            toast.success("Event Updated Successfully");
            setEventModal(false);
            loadTimeLine(row);
          }
        }
      }


    }
    catch (ex) {
      toast.error(ex);
    }
  }

  const openEditEvent = async (item) => {
    try {
      setTimelineId(item);
      setActionType('Edit');

      const requestOptions = {
        headers: { 'Content-Type': 'application/json', Authorization: token }
      };
      const getEvent = await axios.get(`${BaseUrl}/projects/${row._id}/events/${item._id}`, requestOptions);
      if (getEvent.data.success) {

        console.log("event", getEvent.data.data);
        setEvent(getEvent.data.data);
        setEvent(prevState => ({
          ...prevState,
          eventDate: moment(getEvent.data.data.eventDate).format("yyyy-MM-DD")
        }));

        const list = [...attatchment];
        getEvent.data.data.attachements.map((item, index) => {
          list[index]['fileName'] = item.fileName;
          list[index]['filePath'] = item.filePath;
          setAttatchment(list);
        })

        setEventModal(true);

      }
    }
    catch (ex) {

    }
  }
  //Event Code End
  // Project code 

  useEffect(() => {
    if (projectModal === false) {
      setProject({
        projectName: '',
        description: ''
      });
    }
  }, [projectModal]);

  const addProject = async () => {
    try {
      const requestOptions = {
        headers: { 'Content-Type': 'application/json', Authorization: token }
      };

      if (!project.projectName) {
        toast.error('Project Name is required ');
      }
      else if (!project.description) {
        toast.error("description is required");
      }
      else {
        /* const data = {
         projectName:project.projectName,
          description: project.description
  
        }; */ console.log(project);
        if (actionType === 'Add') {


          const addedProject = await axios.post(`${BaseUrl}/clients/${_id}/projects`, project, requestOptions);
          if (addedProject.data.success) {
            setProjectModal(false);
            toast.success("Project Added Successfully");
            loadProjects()
          }
        }
        else {
          const updatedProject = await axios.put(`${BaseUrl}/clients/${_id}/projects/${row._id}`, project, requestOptions);
          if (updatedProject.data.success) {

            toast.success("Project Updated Successfully");
            setProjectModal(false);
            loadProjects();
          }
        }
      }


    }
    catch (ex) {
      toast.error(ex);
    }
  }

  const handleChangeProject = (event) => {
    const { name, value } = event.target;
    setProject(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const openEditProject = async (item) => {
    try {
      //setTimelineId(item);
      setRow(item);
      setActionType('Edit');
      const requestOptions = {
        headers: { 'Content-Type': 'application/json', Authorization: token }
      };
      const getProject = await axios.get(`${BaseUrl}/clients/${_id}/projects/${item._id}`, requestOptions);
      if (getProject.data.success) {

        console.log(getProject.data.data);
        setProject(getProject.data.data);
        setProjectModal(true);

      }
    }
    catch (ex) {

    }
  }

  const handleDeleteTimeline = (row) => {
    setTimelineId(row);
    console.log("timeline", row);
    setDeleteProject(false);
    setShowDeleteModal(true);

  }

  const handleDeleteProject = (row) => {
    setRow(row);
    console.log("project", row);
    setDeleteProject(true);
    setShowDeleteModal(true);

  }

  const DeleteClient = async () => {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json', Authorization: token }
    };
    let deleteComany = {};
    if (deleteProject === true) {
      deleteComany = await axios.delete(`${BaseUrl}/clients/${_id}/projects/${row._id}`, requestOptions);
    }
    else {
      deleteComany = await axios.delete(`${BaseUrl}/projects/${row._id}/events/${timelineId._id}`, requestOptions);
    }

    // console.log("deleted product", deleteProduct);
    if (deleteComany.data.success == true) {
      (deleteProject === true ? toast.success("Project Deleted Successfully")
        : toast.success("Event Deleted Successfully"));
      (deleteProject === true ? loadProjects() : loadTimeLine(row));

      setShowDeleteModal(false);

      (deleteProject === true ? setEventBtn(false) : setEventBtn(true));
      (deleteProject === true ? setRow({}) : setTimelineId({}));
    }
  }
  return (
    <>
      <div className="wrapper">
        <Layout />
        <div className="">
          <div className="headig-0">
            <span class="clientname">Client Name: </span>
            <Button className='pull-right btn-sm'> <Link to={`/clients`} style={{ color: "#ffffff" }}>Back</Link> </Button>
          </div>
          <div className="body-content">
            <div className="project-list">
              <div className="project-btn">
                <button type="button" className="btn btn-light" onClick={() => setProjectBtn("Ongoing")}>Ongoing</button>
                <button type="button" className="btn btn-light" onClick={() => setProjectBtn("Completed")} >Completed</button>
              </div>
              <div >

                {projectBtn === 'Ongoing' ? (
                  projectOngoing.map((item, index) => (
                    <div className='clientProject'>
                      <span key={index + item._id} className='list' onClick={() => loadTimeLine(item)}>{item.projectName}</span>
                      {userType === 'Admin' ? <Button variant='light' className='btn-sm'> <ReactIcons.BsFillPencilFill onClick={() => openEditProject(item)} /> </Button> : ""}
                      {userType === 'Admin' ? <Button variant='light' className='btn-sm'> <ReactIcon.RiDeleteBin6Line onClick={() => handleDeleteProject(item)} /> </Button> : ''}
                    </div>
                  )))
                  : (projectCompleted.map((item1, index) => (
                    <div className='clientProject'>
                      <span key={index + item1._id} className='list' onClick={() => loadTimeLine(item1)}>{item1.projectName}</span>
                      {userType === 'Admin' ? <Button variant='light' className='btn-sm'> <ReactIcons.BsFillPencilFill onClick={() => openEditProject(item1)} /> </Button> : ""}
                      {userType === 'Admin' ? <Button variant='light' className='btn-sm'> <ReactIcon.RiDeleteBin6Line onClick={() => handleDeleteProject(item1)} /> </Button> : ''}
                    </div>
                  )))
                }
              </div>

              <div className="addBtnPrt">
                {userType === 'Admin' ? <Button className='btn-sm' onClick={() => setProjectModal(true)}>Add Project</Button> : ''}
              </div>
              <div>

              </div>



            </div>

            <div class="main" style={{ overflow: 'auto' }}>
              <div>
                {eventBtn ? <h3 class="heading pull-left">{row.projectName}</h3> : ''}
                {eventBtn && userType === 'Admin' ? <Button className='btn-secondary btn-sm pull-right' onClick={() => openEventModal()} id="add-event">Add Event</Button> : ''}
              </div>
              <div class="container" >
                <ul>
                  {timeline.map((item, index) => (
                    <li key={index + item._id}>
                      <h3 class="title">{item.eventName}</h3>
                      <div className="timeline-title pull-right">
                        {userType === 'Admin' ? <Button variant='light' className='btn-sm'><ReactIcons.BsFillPencilFill onClick={() => openEditEvent(item)} /></Button> : ''} &nbsp;
                        {userType === 'Admin' ? <Button variant='light' className='btn-sm'><ReactIcon.RiDeleteBin6Line onClick={() => handleDeleteTimeline(item)} /> </Button> : ''}  &nbsp;
                        {item.description ? <TriggerExample value={item.description} /> : ''}
                      </div>
                      <div className="attatches">
                        {item.attachements && item.attachements.map((doc, index) => (
                          <a href={doc.filePath} target='_blank' key={index + doc._id} className='list'>{doc.fileName}</a>
                        ))}
                      </div>
                      <span className={item.clientActionRequired ? 'circle-0' : 'circle'}></span>
                      <span className="date">{item.eventDate}</span>
                    </li>
                  ))}

                </ul>
              </div>

              <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                  <Button variant="primary" onClick={() => DeleteClient()}>Delete</Button>
                </Modal.Footer>
              </Modal>
              <Modal
                size="lg"
                show={projectModal}
                onHide={() => setProjectModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {actionType === 'Add' ? "Add Project" : "Update Project"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>



                  <div className="row">
                    <div className="col-lg-7">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Project Title
                        </Form.Label>
                        <Form.Control type="text" placeholder='' name='projectName' value={project.projectName}
                          onChange={handleChangeProject} />

                      </Form.Group>
                    </div>


                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Project Description
                        </Form.Label>
                        <Form.Control type="text" placeholder='' name='description' value={project.description}
                          onChange={handleChangeProject} />

                      </Form.Group>
                    </div>
                  </div>

                </Modal.Body>
                <Modal.Footer>

                  <Button variant="primary" onClick={addProject}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>


              <Modal
                size="lg"
                show={eventModal}
                onHide={() => setEventModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {actionType === 'Add' ? "Add Event" : "Update Event"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <div className="row">
                    <div className="col-lg-7">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Date
                        </Form.Label>
                        <Form.Control type="Date" placeholder="" name='eventDate' value={event.eventDate}
                          onChange={handleChange} />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Activity Title
                        </Form.Label>
                        <Form.Control type="text" placeholder='' name='eventName' value={event.eventName}
                          onChange={handleChange} />

                      </Form.Group>
                    </div>


                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Activity Description
                        </Form.Label>
                        <Form.Control type="text" placeholder='' name='description' value={event.description}
                          onChange={handleChange} />

                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <Form.Label>Client Action Required
                      </Form.Label>
                      <Form.Control
                        type="checkbox"
                        name="clientActionRequired"
                        checked={event.clientActionRequired}
                        onChange={handleChange}
                      />
                    </div>
                  </div>




                  <div className="row">
                    <div className="col-lg-6">

                      {attatchment.map((item, index) => (
                        <Form.Group controlId="formGridEmail">
                          <Form.Label>Attatchment {index} Title</Form.Label>
                          <Form.Control
                            type="text"
                            name='fileName'
                            value={attatchment[index].fileName}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <Form.Control
                            type="file"
                            name="filePath"
                            onChange={(e) => handleOnFileUpload(e, index)}
                          />
                        </Form.Group>
                      ))}


                    </div>
                  </div>




                </Modal.Body>
                <Modal.Footer>

                  <Button variant="primary" onClick={addEvent}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>




          </div>






        </div>
      </div >
      <Footer />
    </>
  )
};



export default Clients