import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import Sidebar2 from "./sidebar2";
const Layout = () => {
  return (
    <>
      <Header />
    </>);
}
export default Layout;