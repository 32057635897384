import { BrowserRouter,HashRouter,Route,Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import Clients from '../pages/Client/clients';
import Login from '../pages/Login';
import ClientPortal from '../pages/Client/clientportal';
import User from '../pages/User/user';


const Alltheroutes=()=>{
    return(<>
     <HashRouter>
       {/* main component routes */}
        <Routes>
        <Route path="/" element={<Login />} />       
        <Route path="clients" element={<Clients />} />      
        <Route path="client/:_id" element={<ClientPortal />} />
        <Route path="client/:_id/user" element={<User />} />
        </Routes>
     
            
        </HashRouter>
    
    
    </>);

}
export default Alltheroutes