import React, { useState, useEffect } from 'react'
import Layout from '../layout/layout'
import Footer from '../layout/footer'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const data = [
    {
        id: 1,
        name: 'John Doe',
        age: 30,
        gender: 'Male',
    },
    {
        id: 2,
        name: 'Jane Doe',
        age: 25,
        gender: 'Female',
    },
    {
        id: 3,
        name: 'Bob Smith',
        age: 40,
        gender: 'Male',
    },
];


const BaseUrl = `https://pttracker.acompworld.com/backend/api/v1`;
const User = () => {

    const [lgShow, setLgShow] = useState(false);
    const [actionType, setActionType] = useState('Add');
    const token = localStorage.getItem("token");
    const [rowId, setRowId] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [data, setData] = useState([]);
    const {_id} = useParams();
    const[user,setUser]=useState(
        {
            fullName:"",
            password:"",
            email:"",
            client:_id,
            userType:"ClientUser"
        }
    );

    const columns = [
        {
            name: 'S.No',
            selector: '_id',
            sortable: true,
        },
        {
            name: 'User',
            selector: 'fullName',
            sortable: true,
            
        },{
            name: 'Email',
            selector: 'email',
            sortable: true,
            
        },

        {
            name: 'Action',
            selector: 'id',
            cell: (row) => <ActionColumn row={row} />,
            width: '200px',

        },
    ];



    const ActionColumn = ({ row }) => {
        return (
            <>
                <Button variant='default' className="action-icon" onClick={() => OpenEditCompany(row)}
                >

                    Edit
                </Button>

                <Button variant='default' className="action-icon" onClick={() => handleDelete(row)}
                >

                    Delete
                </Button>


            </>
        );
    };


    const addUser = async () => {

        try {
            if (!user.fullName)
                toast.error("full Name is Required");
            else if (!user.email){
                toast.error("email is Required");
            }
            else if (!user.password){
                toast.error("password is Required");
            }
            else {
                if (actionType == 'Add') {
                   

                    console.log("token", token)
                    const requestOptions = {
                        headers: { 'Content-Type': 'application/json', Authorization: token }
                    };
                    const addedCompany = await axios.post(`${BaseUrl}/clients/${_id}/users`, user, requestOptions);
                    if (addedCompany.data.success) {
                        toast.success("User Added Successfully");
                        setLgShow(false);
                        //loadClient();
                    }
                }
                else {
                   

                    const requestOptions = {
                        headers: { 'Content-Type': 'application/json', Authorization: token }
                    };

                    const updateCompany = await axios.put(`${BaseUrl}/clients/${_id}/users/${rowId._id}`, user, requestOptions);
                    if (updateCompany.data.success) {
                        toast.success("User Updated Successfully");
                        setLgShow(false);
                        loadClient();

                    }


                }

            }

        }

        catch (ex) {
            toast.error(ex);
        }
    }

    const OpenEditCompany = async (row) => {
        try {
            console.log("row--", row)
            setRowId(row);
            setActionType('Edit');
            const requestOptions = {
                headers: { 'Content-Type': 'application/json', Authorization: token }
            };
            const getClient = await axios.get(`${BaseUrl}/clients/${_id}/users/${row._id}`, requestOptions);
            if (getClient.data.success) {
               
                const result = getClient.data.data;
                setUser(result);
                setUser(prevState => ({
                    ...prevState,
                    client:  _id
                  }));
                setLgShow(true);

            }

        }
        catch (ex) {

        }
    }

    const handleDelete = (row) => {
        setRowId(row);
        setShowDeleteModal(true);

    }

    const DeleteClient = async () => {
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', Authorization: token }
        };
        const deleteComany = await axios.delete(`${BaseUrl}/clients/${_id}/users/${rowId._id}`,requestOptions);
        // console.log("deleted product", deleteProduct);
        if (deleteComany.data.success == true) {
            toast.success("User Deleted Successfully");

            setShowDeleteModal(false);
            loadClient();


        }
    }

    const loadClient = async () => {

        try {
            const requestOptions = {
                headers: { 'Content-Type': 'application/json', Authorization: token }
            };
            const result = await axios.get(`${BaseUrl}/clients/${_id}/users`, requestOptions);
            if (result.data.success) {
               
                setData(result.data.data);

            }
        }
        catch (ex) {
            toast.error(ex);
        }
    }

    useEffect(() => {


        loadClient();


    }, [])

    useEffect(() => {
        if (lgShow == false) {
            loadClient();
           setUser({
            fullName:"",
            password:"",
            email:"",
            client:_id,
            userType:"ClientUser"
           })
            setActionType('Add');
        }
    }, [lgShow]);

    const handleChangeProject = (event) => {
        const { name, value } = event.target;
        setUser(prevState => ({
          ...prevState,
          [name]:  value
        }));
      }

    return (
        <>
            <div className="wrapper">

                <Layout />
                <div className="">

                    <div className="heading-compines">
                        <h2>Users</h2>
                    </div>
                    <div className="uper-btn-compines">

                        <div className="add-companies-btn">
                            <Button onClick={() => setLgShow(true)}
                            >Add User</Button>

                            <Button> <Link to={`/clients`}>Back</Link> </Button>
                        </div>
                    </div>

                    <Modal
                        size="lg"
                        show={lgShow}
                        onHide={() => setLgShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                            {actionType === 'Add' ? "Add New USer" : "Update User"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="row">
                                <div className="col-lg-7">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>User Name
                                        </Form.Label>
                                        <Form.Control type="text" name='fullName' placeholder='' value={user.fullName}
                                            onChange={handleChangeProject} />

                                    </Form.Group>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-7">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Password
                                        </Form.Label>
                                        <Form.Control type="text" name='password' placeholder='' value={user.password}
                                            onChange={handleChangeProject} />

                                    </Form.Group>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-7">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Email
                                        </Form.Label>
                                        <Form.Control type="text" name='email' placeholder='' value={user.email}
                                            onChange={handleChangeProject} />

                                    </Form.Group>
                                </div>
                            </div>

                            

                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="primary" onClick={addUser}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                            <Button variant="primary" onClick={() => DeleteClient()}>Delete</Button>
                        </Modal.Footer>
                    </Modal>

                    <DataTable
                        columns={columns}
                        data={data}
                        title=""
                        className="table-tab"
                        pagination
                        highlightOnHover
                    />

                </div>

            </div>

            <Footer />
        </>
    )
}

export default User