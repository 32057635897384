import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import APIHandler from '../helper/APIHandler'
import loginByPassword from '../helper/OnboardUser'
import { toast } from 'react-toastify';
import JSON from 'json3';


export default function Login() {
    const navigate = useNavigate()
    const [mobileOrEmail, setMobileOrEmail] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [adminPass, setadminPass] = useState("")


    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        const data = {
            email: mobileOrEmail,
            password: password
        }
        console.log("data fron start", data)

        if (mobileOrEmail === "" || mobileOrEmail === undefined || mobileOrEmail === null) {
            alert("Please User Name")
            // return toast.error("Please User Name")
        }
        else if (password === null || password === undefined || password === "") {
            alert("Please  password")
        }
        else {
            loginByPassword(data).then(result => {
                console.log(result)
                if (result !== undefined) {
                    if (result.success === true) {
                        console.log(result);
                        let tokenUser = result.data.token;
                        localStorage.setItem("token", tokenUser)
                        localStorage.setItem("userType", result.data.user.userType);
                        if (result.data.user.userType === "ClientUser") {
                            navigate(`/client/${result.data.user.client._id}`);
                        }
                        else {
                            navigate('/clients');
                        }
                    }
                }
                else {
                    alert("User not found")
                }
            })
        }
    };

    return (
        <div className='login'>
            <h2>Project Timeline Tracker</h2>
            <div className="Auth-form-container">
                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title"> Login</h3>
                        <div className="form-group mt-3">
                            <label>Email</label>
                            <input
                                type="text"
                                className="form-control mt-1"
                                placeholder="Enter User Name " value={mobileOrEmail} maxLength="255" onChange={(e) => setMobileOrEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control mt-1"
                                placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                                Login
                            </button>
                        </div>
                        <p className="forgot-password text-right mt-2">
                            {message}
                        </p>
                    </div>
                </form>
            </div>
        </div>
    )
}
